import React from 'react'
import Typography from '@material-ui/core/Typography'
import JsonField from './JsonField'
import StructuredJsonBlockFields from './StructuredJsonBlockFields'

export default function PresetComponentsList(props) {
  const { jsonPath, api, label, value = {}, components, wrapped, onUpdate } = props
  const { component, ...fields } = value
  return (
    <>
      {wrapped && <Typography style={{ fontSize: 10.4, marginBottom: 4 }}>{label}</Typography>}
      <div
        style={
          wrapped
            ? {
                padding: '8px 12px',
                border: '1px solid rgb(204 204 204)',
                borderRadius: 4,
              }
            : null
        }
      >
        {components.length > 1 && (
          <JsonField
            jsonPath={`${jsonPath}.component`}
            label="component type"
            type="enum"
            value={value.component}
            options={components}
            onUpdate={onUpdate}
          />
        )}
        <PresetFieldsBLock
          component={component}
          fields={fields}
          jsonPath={jsonPath}
          api={api}
          onUpdate={onUpdate}
        />
      </div>
    </>
  )
}

function PresetFieldsBLock(props) {
  const { component, fields, jsonPath, api, onUpdate } = props
  const schema = predefinedSchemas[component]
  return (
    <StructuredJsonBlockFields
      index={-1}
      fields={fields}
      schema={schema}
      jsonPath={jsonPath}
      api={api}
      onUpdate={onUpdate}
    />
  )
}

// TODO: MOVE TO admin-api/cxm/JsonSchemas.ts 
export const predefinedSchemas = {
  jamJurySlide: {
    title: {
      label: 'Slide title',
      type: 'string',
    },

    // image
    imagePreview: {
      type: 'media',
      label: 'Image',
      mediaProps: {
        path: 'imagePath',
        type: 'imageType',
      },
    },
    imageType: {
      type: 'string',
      label: 'Image type',
      fieldStyles: { marginLeft: 100 },
      inputProps: { disabled: true },
    },
    imagePath: {
      type: 'string',
      label: 'Image path',
      fieldStyles: { marginLeft: 100 },
      inputProps: { disabled: true },
    },

    // audio
    audioPreview: {
      type: 'media',
      label: 'Audio',
      mediaProps: {
        path: 'audioPath',
        type: 'audioType',
      },
    },
    audioType: {
      type: 'string',
      label: 'Audio type',
      fieldStyles: { marginLeft: 100 },
      inputProps: { disabled: true },
    },
    audioPath: {
      type: 'string',
      label: 'Audio path',
      fieldStyles: { marginLeft: 100 },
      inputProps: { disabled: true },
    },

    // audio cover
    audioCoverPreview: {
      type: 'media',
      label: 'Audio cover',
      mediaProps: {
        path: 'audioCoverPath',
        type: 'audioCoverType',
      },
    },
    audioCoverType: {
      type: 'string',
      label: 'Audio cover type',
      fieldStyles: { marginLeft: 100 },
      inputProps: { disabled: true },
    },
    audioCoverPath: {
      type: 'string',
      label: 'Audio cover path',
      fieldStyles: { marginLeft: 100 },
      inputProps: { disabled: true },
    },

    audioTitle: {
      label: 'Audio title',
      type: 'string',
    },
    copy: {
      label: 'Slide Copy',
      type: 'richtext',
    },
  },
  media: {
    preview: { type: 'media' },
    type: {
      type: 'string',
      fieldStyles: { marginLeft: 100 },
      inputProps: { disabled: true },
    },
    path: {
      type: 'string',
      fieldStyles: { marginLeft: 100 },
      inputProps: { disabled: true },
    },
  },
  text: {
    title: { type: 'string' },
    content: { type: 'string' },
  },
}
