/* eslint-disable react-hooks/exhaustive-deps */
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import ChartsEmbeddedSDK from '@mongodb-js/charts-embed-dom'
import React, { useEffect, useRef, useState } from 'react'

import DashboardFilters from './DashboardFilters'
import { getToken, loadFiltersFromLocalStorage } from './atlasHelper'

const styles = () => ({
  atlasDashboardWrapper: {
    height: 'calc(100vh - 140px)',
  },
  atlasDashboardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 2.4rem',
  },
  atlasDashboardInfo: {
    paddingTop: '0.4rem',
  },
})

function AtlasDashboardPage({ data, classes, api, history }) {
  // Renders an Atlas Chart
  const dashboardDiv = useRef(null)
  const [atlasDashboard, setAtlasDashboard] = useState()

  const { baseUrl, dashboard } = data
  const dashboardId = dashboard?.dashboardId
  const sdk = new ChartsEmbeddedSDK({
    baseUrl,
    getUserToken: async () => getToken(api),
    showAttribution: false,
  })

  useEffect(() => {
    ;(async () => {
      let dash = sdk.createDashboard({
        background: 'transparent',
        dashboardId,
        widthMode: 'scale',
        filter: loadFiltersFromLocalStorage(dashboardId) || {},
      })
      try {
        await dash.render(dashboardDiv.current)
        setAtlasDashboard(dash)
      } catch (err) {
        console.error(err)
      }
    })()
  }, [dashboardId])

  const handleClick = () => {
    history.push('/atlas-dashboards')
  }

  return (
    <div className={classes.atlasDashboardWrapper}>
      <Typography className={classes.atlasDashboardTitle} component="div">
        <IconButton className={classes.backButton} onClick={handleClick}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <Typography as="div" className={classes.atlasDashboardInfo}>
          <Typography variant="h4">{dashboard.name}</Typography>
          <Typography variant="subtitle1">{dashboard.subtitle}</Typography>
        </Typography>
      </Typography>
      <DashboardFilters dashboard={atlasDashboard} dashboardId={dashboardId} />
      <div ref={dashboardDiv} style={{ height: '100%' }} />
    </div>
  )
}

export default withStyles(styles)(AtlasDashboardPage)
