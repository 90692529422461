import FormControl from '@material-ui/core/FormControl/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText'
import Icon from '@material-ui/core/Icon/Icon'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Input from '@material-ui/core/Input/Input'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import { capitalize } from '@material-ui/core/utils/helpers'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

const styles = ({ palette, spacing: { unit } }) => ({
  root: {},
  input: {
    textAlign: 'center',
  },
  button: {
    padding: 0,
    width: unit * 3,
    height: unit * 3,
  },
  buttonDisabled: {
    color: palette.action.disabled,
  },
  buttonStart: {
    marginLeft: -unit * 0.5,
    marginRight: 0,
  },
  buttonEnd: {
    marginRight: -unit * 0.5,
  },
})

const IntegerField = ({
  className,
  id,
  classes,
  label,
  value: propValue,
  name,
  min,
  max,
  onChange,
  onBlur,
  helperText,
  disabled,
  inputProps,
  InputProps,
  fullWidth,
  step = 1,
}) => {
  const [value, setValue] = useState(propValue)
  const inputRef = useRef(null)

  useEffect(() => {
    setValue(propValue)
  }, [propValue])

  const handleBlur = (e) => {
    let newValue = parseFloat(e.target.value)
    if (isNaN(newValue)) {
      newValue = 0
    }
    if (max !== undefined) {
      newValue = Math.min(max, newValue)
    }
    if (min !== undefined) {
      newValue = Math.max(min, newValue)
    }
    setValue(newValue)
    onBlur &&
      onBlur({
        target: {
          type: 'number',
          name,
          value: newValue,
        },
      })
  }

  const handleButtonClick = (add) => () => {
    if (disabled) {
      return
    }

    inputRef.current && inputRef.current.focus()

    let newValue = parseFloat(value)
    if (isNaN(newValue)) {
      newValue = 0
    }

    if (add) {
      newValue += step
    } else {
      newValue -= step
    }

    if (max !== undefined) {
      newValue = Math.min(max, newValue)
    }
    if (min !== undefined) {
      newValue = Math.max(min, newValue)
    }

    setValue(newValue)
    onChange && onChange(id, newValue)
  }

  const getButtonProps = (isDisabled, position, handler) => {
    return {
      className: classNames(classes.button, classes[`button${capitalize(position)}`], {
        [classes.buttonDisabled]: isDisabled,
      }),
      disableRipple: isDisabled,
      disableTouchRipple: isDisabled,
      onClick: handler,
      // onMouseDown: handleButtonDown,
      // onTouchStart: handleButtonDown,
      tabIndex: -1,
    }
  }

  const addDisabled = max !== undefined && value >= max
  const subtractDisabled = min !== undefined && value <= min

  const { classes: inputPropsClasses = {} } = InputProps || {}

  return (
    <FormControl className={classNames(className, classes.root)} fullWidth={fullWidth}>
      {label && <InputLabel>{label}</InputLabel>}
      <Input
        inputRef={inputRef}
        inputProps={inputProps}
        min={min}
        max={max}
        disabled={disabled}
        value={value}
        name={name}
        onChange={(e) => {
          setValue(e.target.value)
          onChange && onChange(id, e.target.value)
        }}
        onBlur={handleBlur}
        startAdornment={
          <InputAdornment position="start" style={{ marginRight: 0 }}>
            <IconButton {...getButtonProps(subtractDisabled || disabled, 'start', handleButtonClick(false))}>
              <Icon>remove</Icon>
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end" style={{ marginLeft: 0 }}>
            <IconButton {...getButtonProps(addDisabled || disabled, 'end', handleButtonClick(true))}>
              <Icon>add</Icon>
            </IconButton>
          </InputAdornment>
        }
        {...InputProps}
        classes={{ ...inputPropsClasses, input: classes.input }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default withStyles(styles)(IntegerField)
