/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import Icon from '@material-ui/core/Icon'
import Modal from '@material-ui/core/Modal'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import withStyles from '@material-ui/core/styles/withStyles'

import { styles } from '../CustomMedia/Styles'
import ListMediaContainer from '../CustomMedia/ListMediaContainer'
import PreviewMedia from '../CustomMedia/PreviewMedia'
import UploadMediaContainer from '../CustomMedia/UploadMediaContainer'
import sizeImagePath from '../../../../shared/utils/sizeImagePath'

const IMAGE_MAX_SIZE = 1000000000

export default withStyles(styles, { withTheme: true })(PresetMediaSelection)

function PresetMediaSelection(props) {
  const { classes, open, onClose } = props
  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.paper}>
        <div className={classes.close} onClick={onClose}>
          <Icon>close</Icon>
        </div>
        <MediaList {...props} />
      </div>
    </Modal>
  )
}

function MediaList(props) {
  const { api, classes, onUpdate } = props

  const [isFetchingMedia, setFetchingMedia] = useState(false)
  const [upstreamFiles, setUpstreamFiles] = useState([])
  const [filteredMediaFiles, setFilteredMediaFiles] = useState([])
  const [localFiles, setLocalFiles] = useState([])
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [currentFile, setCurrentFile] = useState()
  const [showFullImage, setShowFullImage] = useState(false)
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [tags, setTags] = useState([])
  const [parsedTags, setParsedTags] = useState([])
  const [tagsOnUpload, setTagsOnUpload] = useState()
  const [types, setTypes] = useState([])

  useEffect(() => {
    fetchAllMedias()
  }, [])

  useEffect(() => {
    setFilteredMediaFiles(upstreamFiles)
  }, [upstreamFiles])

  async function fetchAllMedias() {
    setFetchingMedia(true)
    const { request } = api.getMedias()
    const { mediaItems, filter } = await request
    setUpstreamFiles(mediaItems)
    filter?.tags && setTags(filter.tags)
    filter?.tags?.length && setParsedTags(filter.tags.map((t) => ({ value: t, label: t })))
    filter?.types && setTypes(filter?.types)
    setFetchingMedia(false)
  }

  function handleTabChange(event, tabIndex) {
    setActiveTabIndex(tabIndex)
  }
  // function handleChangeIndex() {}
  function handleContentType(file, base64) {
    if (/image/.test(file.type)) {
      return base64 ? file.src : sizeImagePath(file.path, 'xxs')
    } else if (/pdf/.test(file.type)) {
      return '/static/images/pdf_black.png'
    } else if (/audio|video/.test(file.type)) {
      return '/static/images/video_black.png'
    }
  }
  function handleSearchMedias({ valueType, valueTag, valueName }) {
    const newFilteredMediaFiles = upstreamFiles.filter(
      (file) =>
        (valueType.length === 0 || valueType.includes(file.type)) &&
        (valueTag.length === 0 || file.tags.some((tag) => valueTag.includes(tag))) &&
        (valueName === '' || file.name.indexOf(valueName) !== -1),
    )
    setFilteredMediaFiles(newFilteredMediaFiles)
  }
  function handleUseThisMedia({ path, type }) {
    onUpdate({ path, type })
  }
  // function handleDeleteMedia() {}
  function handleOnDrop(newFiles) {
    if (!newFiles) return

    const allFiles = [...localFiles, ...newFiles]
    if (!allFiles || allFiles.length === 0) return

    allFiles.forEach((file, index) => {
      if (file.src) return
      const reader = new FileReader()
      reader.onload = function () {
        file.src = reader.result
        allFiles[index] = file
        setLocalFiles(allFiles)
      }
      reader.readAsDataURL(file)
    })
  }
  function handleViewFullImage(file) {
    setCurrentFile(file)
    setShowFullImage(true)
    setActiveTabIndex(2)
  }
  async function handleAddTagToFile(tags = []) {
    try {
      const update = { set: { tags: tags } }
      const { request } = api.updateMedia(currentFile._id, update)
      await request
    } catch (e) {
      console.error(e)
    }
    setCurrentFile(Object.assign({}, currentFile, { tags }))
  }
  async function handleCreateTag(inputValue, parsedValues, isUpload) {
    // update tags available for search
    const newOption = { label: inputValue, value: inputValue }
    const newParsedTags = [...parsedTags].push(newOption)
    setParsedTags(newParsedTags)
    if (isUpload) {
      // here we are preparing the tags for a file that will be uploaded
      parsedValues.length && setTagsOnUpload(parsedValues)
    } else {
      // in this case we are adding tags for the already uploaded files
      handleAddTagToFile(parsedValues)
    }
  }
  function handleUploadSingleFile(file) {
    handleUploadFiles(file)
  }
  async function handleUploadFiles(file) {
    const files = file ? [file] : [...localFiles]
    setUploadInProgress(true)
    try {
      for (const file of files) {
        const { request } = api.uploadMedia(null, file, tagsOnUpload)
        await request
      }
    } catch (e) {
      console.error(e)
    }
    setLocalFiles([])
    fetchAllMedias()
    setUploadInProgress(false)
  }
  function handleRemoveLocalFile(fileToRemove) {
    const newLocalFiles = [...localFiles].filter((file) => fileToRemove.name !== file.name)
    setLocalFiles(newLocalFiles)
  }
  async function handleBackToFilesList() {
    await fetchAllMedias()
    setActiveTabIndex(0)
    setShowFullImage(false)
  }

  return (
    <>
      {!showFullImage && (
        <Tabs
          value={activeTabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="MEDIA" />
          <Tab label="UPLOAD" />
        </Tabs>
      )}

      <SwipeableViews
        // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeTabIndex}
        // onChangeIndex={handleChangeIndex}
      >
        <ListMediaContainer
          classes={classes}
          searchMedias={handleSearchMedias}
          contentType={handleContentType}
          upstreamFiles={filteredMediaFiles}
          // deleteFile={handleDeleteMedia}
          useThisMedia={handleUseThisMedia}
          viewFullImage={handleViewFullImage}
          types={types}
          tags={tags}
          // isList={isList}
          isLoading={isFetchingMedia}
        />

        <UploadMediaContainer
          classes={classes}
          contentType={handleContentType}
          imageMaxSize={IMAGE_MAX_SIZE}
          handleOnDrop={handleOnDrop}
          uploadFile={handleUploadSingleFile}
          uploadAllFiles={() => handleUploadFiles()}
          removePreviewFile={handleRemoveLocalFile}
          previewFiles={localFiles}
          tagsOnUpload={tagsOnUpload}
          visible={uploadInProgress}
          // handleAddChipOnUpload={handleAddTagToFile}
          // handleDeleteChipOnUpload={handleDeleteChipOnUpload}
          // onHandleCreate={handleCreateTag}
          hideTagSelection={true}
          parsedTags={parsedTags}
        />

        {showFullImage ? (
          <PreviewMedia
            classes={classes}
            currentFile={currentFile}
            onClickHandle={handleBackToFilesList}
            onHandleAddChip={handleAddTagToFile}
            useThisMedia={handleUseThisMedia}
            parsedTags={parsedTags}
            // localiseFile={this.localiseFile.bind(this)}
            onHandleCreate={handleCreateTag}
            // onAutoplayChange={this.handleAutoplayChange}
            // isList={isList}
            handleOnDrop={handleOnDrop}
            removePreviewFile={handleRemoveLocalFile}
            previewFiles={localFiles}
            imageMaxSize={IMAGE_MAX_SIZE}
            allowLocaliseMedia={false}
          />
        ) : (
          <React.Fragment />
        )}
      </SwipeableViews>
    </>
  )
}
