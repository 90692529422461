import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { withApi } from '../../../../shared/api/ApiContext'
import Switch from '@material-ui/core/Switch'
import { FormControlLabel, Typography } from '@material-ui/core'

const styles = (theme) => ({
  timelineStep: {
    opacity: 1,
    position: 'relative',
    paddingBottom: 16,
    paddingLeft: 24,
    counterIncrement: 'flow-timeline',

    '&:before': {
      content: 'counter(flow-timeline)',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: -4,
      transform: 'translateX(-50%) translateY(50%)',
      fontFamily: theme.typography.fontFamily,
      width: 24,
      height: 24,
      fontWeight: 700,
      borderRadius: '0 50% 50% 0',
      backgroundColor: '#EFEFEF',
    },
  },
  timelineStepDisabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  standaloneTitle: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 48,
  },
  stepControls: {
    paddingLeft: 48,
  },
  stepDescription: {
    opacity: 0.6,
    position: 'relative',
    top: -6,
  },
})

class FlowStep extends React.Component {
  render() {
    let { classes, children, title, description, enabled, onChange, alwaysEnabled = false } = this.props

    return (
      <div className={classes.timelineStep}>
        {alwaysEnabled ? (
          <Typography variant="body2" className={classes.standaloneTitle}>
            {title}
          </Typography>
        ) : (
          <FormControlLabel control={<Switch checked={enabled} onChange={onChange} />} label={title} />
        )}
        {/* <div className={classes.timelineStepHeadline}>Customer Scan</div> */}

        <div
          className={classNames(
            classes.timelineStepContent,
            !enabled && !alwaysEnabled && classes.timelineStepDisabled,
          )}
        >
          <div className={classes.stepControls}>
            <Typography variant="body1" className={classes.stepDescription}>
              {description}
            </Typography>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default withApi(withStyles(styles)(FlowStep))
