import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import EditorMenuButton from './EditorMenuButton'
import EditorMenuHeading from './EditorMenuHeading'
import EditorMenuTable from './EditorMenuTable'
import EditorMenuTextAlign from './EditorMenuTextAlign'

const styles = {
  menu: {
    border: '1px solid #ccc',
    padding: '6px 12px',
    // whiteSpace: 'nowrap',
  },
  disabled: {
    '& > *': {
      pointerEvents: 'none',
      opacity: 0.7,
      position: 'relative',
    },
  },
}

function EditorMenu(props) {
  const { classes, editor, settings } = props
  const { heading, bold, italic, small, superscript, link, bullet, ordered, table, textAlign } = settings || {}

  if (!editor) return null

  return (
    <div
      className={['ProseMirror-Menu', classes.menu, !editor.isEditable && classes.disabled]
        .filter(Boolean)
        .join(' ')}
    >
      {heading && <EditorMenuHeading editor={editor} />}
      {bold && <EditorMenuButton editor={editor} type="bold" />}
      {italic && <EditorMenuButton editor={editor} type="italic" />}
      {small && <EditorMenuButton editor={editor} type="small" />}
      {superscript && <EditorMenuButton editor={editor} type="superscript" />}
      {link && <EditorMenuButton editor={editor} type="link" />}
      {bullet && <EditorMenuButton editor={editor} type="bullet-list" />}
      {ordered && <EditorMenuButton editor={editor} type="ordered-list" />}
      {table && <EditorMenuTable editor={editor} />}
      {textAlign && <EditorMenuTextAlign editor={editor} />}
      <EditorMenuButton editor={editor} type="undo" />
      <EditorMenuButton editor={editor} type="redo" />
    </div>
  )
}

export default withStyles(styles)(EditorMenu)
