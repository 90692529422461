import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import ImageSelection from './ImageSelection'
import { styles } from './Styles'

class CustomUserMedia extends React.Component {
  render() {
    const { classes, value, api, settings: {height: customHeight} = {} } = this.props
    value.path = `${api.connector.baseUrl}/@/customUserMedia/${value.filePath.split('/').pop()}`
    value.type = value.mimetype
    const isImage = /image/.test(value.mimetype)
    return (
      <ImageSelection
        showControls={false}
        classes={classes}
        value={value}
        handleOpen={undefined}
        disabled
        handleDeleteImageFromItem={undefined}
        clickedOnVideo={undefined}
        isPlaying={false}
        customUserMedia={isImage}
        customHeight={customHeight}
      />
    )
  }
}

export default withStyles(styles)(CustomUserMedia)
