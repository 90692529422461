import React from 'react'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/neat.css'
import 'codemirror/theme/neo.css'
import 'codemirror/mode/javascript/javascript'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import { withApi } from '../../../../shared/api/ApiContext'
import TextField from '@material-ui/core/TextField'
import DropDown from './DropDown'
import Switch from '@material-ui/core/Switch'
import { Collapse, FormControlLabel, FormHelperText, Typography } from '@material-ui/core'
import FlowStep from './FlowStep'
import { set } from 'lodash'
import JsonEditor from './JsonEditor'

const styles = () => ({
  flowCard: {
    border: '1px solid #EFEFEF',
    backgroundColor: '#FEFEFE',
    marginTop: 8,
    marginBottom: 8,
    position: 'relative',

    '&:last-of-type': {
      marginBottom: 16,
    },
  },
  flowCardHeader: {
    borderBottom: '1px solid #EFEFEF',
    cursor: 'pointer',
    position: 'sticky',
    top: 38,
    zIndex: 40,
    backgroundColor: '#FEFEFE',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,

    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
  flowCardHeaderLeft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flowCardContent: {
    padding: 16,
  },
  flowCardBadge: {},
  inputWrapper: {
    paddingTop: 12,
    paddingBottom: 26,
  },
  spacer: {
    paddingTop: 8,
  },
  closeIcon: {
    display: 'flex',
    // position: 'absolute',
    // top: -24,
    // right: -24,
    // backgroundColor: '#FFF',
    // border: '1px solid #EFEFEF',
    // borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 50,
  },
  timeline: {
    position: 'relative',
    marginTop: 32,
    marginBottom: 32,
    counterReset: 'flow-timeline',

    // '&::before': {
    //   content: '""',
    //   position: 'absolute',
    //   top: 0,
    //   left: -17,
    //   bottom: 0,
    //   width: 2,
    //   backgroundColor: '#EFEFEF',
    //   borderRadius: 2,
    // },
  },
  flowTitle: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  flowDisabledLabel: {
    opacity: 0.4,
    paddingLeft: 4,
  },
  isDisabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
})

class Flow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
      data: {
        id: props.initialData.id,
        key: props.initialData.key || Math.random().toString(16).slice(2),
        enabled: props.initialData.enabled || false,
        sourceType: props.initialData.sourceType,
        labels: props.initialData.labels || {},
        custom: props.initialData.custom || {},
        config: {
          showQrCode: {
            enabled: props.initialData.config.showQrCode.enabled || false,
            method: props.initialData.config.showQrCode.method || 'signup-form',
            showInitially: props.initialData.config.showQrCode.showInitially || false,
            link: props.initialData.config.showQrCode.link || '',
          },
          scanCustomer: {
            enabled: props.initialData.config.scanCustomer.enabled || false,
            method: props.initialData.config.scanCustomer.method || 'confirm',
          },
          scanDevice: {
            enabled: props.initialData.config.scanDevice.enabled || false,
            method: props.initialData.config.scanDevice.method || 'attach',
          },
          attachData: {
            enabled: props.initialData.config.attachData.enabled || false,
            method: props.initialData.config.attachData.method || 'scan-qr-code',
          },
        },
      },
    }
  }

  handleChange = (name, value) => {
    console.log(name, value)
    const newState = { ...this.state }
    set(newState, `data.${name}`, value)
    console.log(name, value, newState.data)
    this.setState(newState)
    this.props.onChange(newState.data)
  }

  async componentDidMount() {}

  render() {
    let { classes, disabled = false } = this.props

    return (
      <div className={classes.flowCard}>
        <div
          className={classes.flowCardHeader}
          onClick={() => this.setState({ ...this.state, expanded: !this.state.expanded })}
        >
          <div className={classes.flowCardHeaderLeft}>
            <Typography variant="h6" className={classes.flowTitle}>
              {this.state.data.id}
              {!this.state.data.enabled && (
                <Typography variant="body2" className={classes.flowDisabledLabel}>
                  - Disabled
                </Typography>
              )}
            </Typography>
          </div>
          <div className={classes.flowCardHeaderRight}>
            <div className={classes.closeIcon}>
              <IconButton
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (window.confirm(`Are you sure you want to delete flow "${this.state.data.id}"?`))
                    this.props.onRemove(this.state.data.id)
                }}
              >
                <Icon fontSize="small">close</Icon>
              </IconButton>
            </div>
          </div>
        </div>
        <Collapse in={this.state.expanded}>
          <div className={classes.flowCardContent}>
            <div className={classes.inputWrapper}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.data.enabled}
                    onChange={(e) => this.handleChange('enabled', e.target.checked)}
                  />
                }
                label="Flow is enabled"
              />
            </div>

            <div className={!this.state.data.enabled && classes.isDisabled}>
              <Grid container spacing={24}>
                <Grid item xs={6} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label={'Flow ID'}
                    fullWidth
                    disabled={disabled}
                    className={classes.textField}
                    value={this.state.data.id}
                    onChange={(e) => this.handleChange('id', e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label={`Source Type`}
                    fullWidth
                    disabled={disabled}
                    className={classes.textField}
                    value={this.state.data.sourceType}
                    onChange={(e) => this.handleChange('sourceType', e.target.value)}
                  />
                </Grid>
              </Grid>

              <div className={classes.timeline}>
                <FlowStep
                  title="Show Registration QR Code"
                  description="Allow the activator to display a QR code that guides the customer to a URL"
                  enabled={this.state.data.config.showQrCode.enabled}
                  onChange={(e) => this.handleChange('config.showQrCode.enabled', e.target.checked)}
                >
                  <div className={classes.inputWrapper}>
                    <DropDown
                      value={this.state.data.config.showQrCode.method}
                      onChange={(e) => this.handleChange('config.showQrCode.method', e.target.value)}
                      options={[
                        {
                          label: 'GetPloom Signup Form',
                          value: 'signup-form',
                        },
                        {
                          label: 'Ploom Website Customer Profile',
                          value: 'ploom-profile',
                        },
                        // {
                        //   label: 'Custom',
                        //   value: 'custom',
                        // },
                      ]}
                    ></DropDown>
                    <FormHelperText>The URL the QR code points to</FormHelperText>
                    <div className={classes.spacer}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.data.config.showQrCode.showInitially}
                            onChange={(e) =>
                              this.handleChange('config.showQrCode.showInitially', e.target.checked)
                            }
                          />
                        }
                        label="Show QR Code initially"
                      />
                      <FormHelperText>
                        <strong>Enabled:</strong> Activator will see the QR code after selecting a flow and
                        will need to click "continue".
                        <br />
                        <strong>Disabled:</strong> Activator will immidiately see the QR-Code scanning page and
                        can show the QR code by clicking a button.
                      </FormHelperText>
                    </div>
                  </div>
                  {/* <div className={classes.inputWrapper}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label={`Custom QR Code URL`}
                    fullWidth
                    disabled={disabled}
                    className={classes.textField}
                    value={item[valueField]}
                  />
                  <FormHelperText>Enter a custom URL for the QR code</FormHelperText>
                </div> */}
                </FlowStep>

                <FlowStep
                  title="Customer confirmation"
                  description="Choose how to confirm and identify a customer"
                  enabled={this.state.data.config.scanCustomer.enabled}
                  onChange={(e) => this.handleChange('config.scanCustomer.enabled', e.target.checked)}
                >
                  <DropDown
                    value={this.state.data.config.scanCustomer.method}
                    onChange={(e) => this.handleChange('config.scanCustomer.method', e.target.value)}
                    options={[
                      {
                        label: 'Check existing customer via profile QR code',
                        value: 'check-existing',
                      },
                      {
                        label: 'Confirm customer registration from GetPloom signup form',
                        value: 'confirm-via-scan',
                      },
                      {
                        label: 'Send an email to registrant to confirm new signups',
                        value: 'confirm-via-mail',
                      },
                    ]}
                  ></DropDown>
                  <FormHelperText></FormHelperText>
                </FlowStep>

                <FlowStep
                  title="Device scan"
                  description="What happens when an activator or a customer scans a device"
                  enabled={this.state.data.config.scanDevice.enabled}
                  onChange={(e) => this.handleChange('config.scanDevice.enabled', e.target.checked)}
                >
                  <DropDown
                    value={this.state.data.config.scanDevice.method}
                    onChange={(e) => this.handleChange('config.scanDevice.method', e.target.value)}
                    options={[
                      {
                        label: 'Attach device to previously identified account',
                        value: 'attach',
                      },
                      {
                        label: 'Track anonymous sale',
                        value: 'anonymous',
                      },
                      // {
                      //   label: 'Check validity of serial number',
                      //   value: 'check-serialno',
                      // },
                    ]}
                  ></DropDown>
                </FlowStep>

                <FlowStep
                  title="Attach data"
                  description="You can optionally let the Activator attach data (For example the ID of a festival wristband)."
                  enabled={this.state.data.config.attachData.enabled}
                  onChange={(e) => this.handleChange('config.attachData.enabled', e.target.checked)}
                >
                  <DropDown
                    value={this.state.data.config.attachData.method}
                    onChange={(e) => this.handleChange('config.attachData.method', e.target.value)}
                    options={[
                      {
                        label: 'Scan QR code',
                        value: 'scan-qr-code',
                      },
                    ]}
                  ></DropDown>
                  <div className={classes.spacer}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.data.config.attachData.isOptional}
                          onChange={(e) => this.handleChange('config.attachData.isOptional', e.target.checked)}
                        />
                      }
                      label="Is optional"
                    />
                    <FormHelperText>
                      <strong>Enabled:</strong> Activator can skip this step
                      <br />
                      <strong>Disabled:</strong> Activator is required to attach data
                    </FormHelperText>
                  </div>
                </FlowStep>
              </div>

              <div className={classes.inputWrapper}>
                <Typography variant="body2" className={classes.stepDescription}>
                  Custom options
                </Typography>
                {/* <pre>{JSON.stringify(this.state.data.custom, null, 2)}</pre> */}
                <JsonEditor
                  value={this.state.data.custom}
                  onUpdate={(data) => {
                    this.handleChange('custom', data)
                  }}
                />
                <FormHelperText>
                  You can include a custom JSON Object in the flow for complex configuration. For example
                  promotion data.
                </FormHelperText>
              </div>

              <div className={classes.inputWrapper}>
                <Typography variant="body2" className={classes.stepDescription}>
                  Label overrides
                </Typography>
                {/* <pre>{JSON.stringify(this.state.data.labels, null, 2)}</pre> */}
                <JsonEditor
                  value={this.state.data.labels}
                  onUpdate={(data) => {
                    this.handleChange('labels', data)
                  }}
                />
                <FormHelperText>
                  You can map labels that are used within the Activation App to other label keys, so specific
                  flows can have different texts.
                </FormHelperText>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    )
  }
}

export default withApi(withStyles(styles)(Flow))
