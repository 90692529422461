import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import StructuredJsonBlockFields from './StructuredJsonBlockFields'

export default function StructuredJsonAccordion(props) {
  const {
    api,
    name,
    label,
    blocks,
    selected,
    schema,
    max,
    jsonPath,
    onAddItem,
    onDeleteItem,
    onSelect,
    onUpdate,
  } = props
  const canAddItem = !max || max > blocks.length
  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        {blocks.filter(Boolean).map((fields, index) => {
          const id = name + index
          return (
            <ExpansionPanel
              expanded={selected === id}
              onChange={() => onSelect(id)}
              elevation={0}
              style={{ border: '1px solid rgb(204 204 204)' }}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {index + 1}. {label || name}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ display: 'inherit', paddingTop: 0 }}>
                <StructuredJsonBlockFields
                  index={index}
                  fields={fields}
                  schema={schema}
                  jsonPath={jsonPath}
                  api={api}
                  onDeleteItem={blocks.length > 1 && onDeleteItem}
                  onUpdate={onUpdate}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })}
      </div>
      {canAddItem && (
        <div style={{ textAlign: 'center', position: 'relative', top: -10 }}>
          <Button
            disableRipple
            onClick={onAddItem}
            variant="outlined"
            style={{ padding: 8, borderRadius: '50%', minWidth: 0 }}
          >
            <AddIcon />
          </Button>
        </div>
      )}
    </>
  )
}
