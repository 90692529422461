/* eslint-disable react-hooks/exhaustive-deps */
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import React, { useEffect, useState } from 'react'

import CustomMenuField from '../CustomMenuField'
import CustomNumber from '../CustomNumber'
import CustomSwitchField from '../CustomSwitchField'
import CustomTextField from '../CustomTextField'
import CustomRichText from '../CustomRichText'
import PresetMediaPreview from './PresetMediaPreview'
import { useJsonData } from './StructuredJson'

export const fieldWrapperStyle = { marginBottom: 12 }

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function JsonField(props) {
  const {
    jsonPath,
    api,
    label,
    value: initialValue,
    type,
    fieldStyles = {},
    inputProps,
    mediaProps,
    onUpdate,
  } = props
  const [value, setValue] = useState(initialValue)
  const { getJsonData } = useJsonData()

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  function handleUpdate(update) {
    const jsonData = getJsonData()
    const newData = cloneDeep(jsonData)
    setValue(update)
    set(newData, jsonPath, update)
    if (JSON.stringify(jsonData) !== JSON.stringify(newData)) {
      onUpdate(newData)
    }
  }

  function handleChange({ target: { value: update } }) {
    handleUpdate(update)
  }

  let Component
  let InputProps = {}
  let options
  let settings

  switch (type) {
    case 'media':
      return (
        <PresetMediaPreview
          jsonPath={jsonPath}
          label={label}
          mediaProps={mediaProps}
          api={api}
          onUpdate={onUpdate}
        />
      )
    case 'number':
      Component = CustomNumber
      InputProps.type = 'number'
      break
    case 'boolean':
      Component = CustomSwitchField
      break
    case 'string':
      Component = CustomTextField
      InputProps.type = 'text'
      break
    case 'richtext':
      Component = CustomRichText
      settings = {
        toolbar: {
          heading: true,
          bold: true,
          textAlign: true,
        },
      }
      break
    case 'enum':
      Component = CustomMenuField
      options = props.options.map((op) => ({ label: op, value: op.trim() }))
      break
    default:
      // In case the type is unknown nothing will be rendered
      return null
  }

  if (inputProps) Object.assign(InputProps, { ...inputProps })

  return (
    <div style={Object.assign({}, fieldWrapperStyle, fieldStyles)}>
      <Component
        fullWidth
        label={capitalizeFirstLetter(label)}
        value={value}
        InputProps={InputProps}
        InputLabelProps={{
          shrink: true,
        }}
        options={options}
        onChange={handleChange}
        onUpdate={handleUpdate}
        settings={settings}
      />
    </div>
  )
}
