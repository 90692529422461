/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect } from 'react'
import StructuredJsonBlock from './StructuredJsonBlock'
import { getDefaultJsonValue } from './jsonFieldHelpers'

export default function StructuredJson(props) {
  const { api, name, label, value = [], onUpdate, settings: { schema, max } = {} } = props

  const initialValue = !value ? getDefaultJsonValue(schema) : value
  const wrapperId = `StructuredJson_${name}`

  const setJsonData = (value) => {
    const wrapper = document.getElementById(wrapperId)
    if (wrapper) wrapper.dataset.value = JSON.stringify(value)
  }

  const getJsonData = () => {
    const wrapper = document.getElementById(wrapperId)
    const { value: datasetValue } = wrapper?.dataset || {}
    if (datasetValue) return JSON.parse(datasetValue)
    else return initialValue
  }

  useEffect(() => {
    setJsonData(initialValue)
  }, [])

  const handleUpdate = (newValue) => {
    onUpdate && onUpdate(newValue)
    setJsonData(newValue)
  }
  return (
    <JsonDataContext.Provider value={{ getJsonData, setJsonData }}>
      <div id={wrapperId}>
        <StructuredJsonBlock
          api={api}
          name={name}
          label={label}
          schema={schema}
          max={max}
          onUpdate={handleUpdate}
        />
      </div>
    </JsonDataContext.Provider>
  )
}

const JsonDataContext = createContext(null)
export const useJsonData = () => useContext(JsonDataContext)
