import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { Component } from 'react'

import PortalLabels from '../../../../constants/PortalLabels'
import { UNIT } from '../../../assets/styles/theme'
import IntlUtil from '../../../utils/IntlUtil'
import PageData from '../../../utils/PageData'
import CheckBox from '../../organisms/CheckBox'
import IntegerField from '../../organisms/IntegerField'
import { MultipleSelectField } from '../../organisms/MultipleSelectField'
import PasswordWithRetype from '../../organisms/PasswordWithRetype'
import RatingField from '../../organisms/RatingField'
import TextInputField from '../../organisms/TextInputField'
import DropDown from '../DropDown'
import SmallButton from '../SmallButton'
import Toggle from '../Toggle/Toggle'

class FormElement extends Component {
  state = {}

  _ref

  static getDerivedStateFromProps({ data, externalError }, prevState) {
    let type

    if (typeof data === 'string') {
      type = 'textLine'
    } else if (typeof data === 'function') {
      type = 'component'
    } else {
      type = data.type
    }

    const isInvalid = externalError ? { isInvalid: true } : {}

    return { type, ...isInvalid, externalError }
  }

  render() {
    const { data, classes, externalError, ...otherProps } = this.props
    const value = this.props.value || data.value
    const { type, isInvalid } = this.state
    let ElementComponent
    let elementProps = {
      className: classes.element,
      value,
      invalid: isInvalid,
      errorText: isInvalid && externalError,
      onFocus: this._handleFieldFocus,
      innerRef: (ref) => (this._ref = ref),
    }

    switch (type) {
      case 'component':
        return data()

      case 'textLine':
        return <Typography className={classes.textLine}>{data}</Typography>

      case 'label':
        return <Typography className={classes.textLine}>{data.value}</Typography>

      case 'smallButton':
        return <SmallButton text={data.placeholder} href={data.to} className={classes.smallButton} />

      case 'spacing':
        ElementComponent = 'div'
        elementProps = { style: { height: UNIT } }
        break

      case 'enum':
        ElementComponent = DropDown
        elementProps.label = data.placeholder
        elementProps.id = data.id
        elementProps.name = data.name
        elementProps.displayEmpty = data.displayEmpty
        elementProps.enumData = data.data
        elementProps.helperText = data.helperText
        elementProps.required = data.required
        elementProps.errorText = data.errorText
        break

      case 'boolean':
        ElementComponent = Toggle
        elementProps.label = data.placeholder
        elementProps.id = data.id
        elementProps.captions = data.data
        break

      case 'multiple':
        ElementComponent = MultipleSelectField
        elementProps.label = data.placeholder
        elementProps.name = data.name
        elementProps.value = data.value
        elementProps.helperText = data.helperText
        elementProps.id = data.id
        elementProps.data = data.data
        elementProps.onChange = data.onChange
        break

      case 'integer':
        ElementComponent = IntegerField
        elementProps.label = data.placeholder
        elementProps.onChange = data.onChange
        elementProps.name = data.name
        elementProps.value = data.value
        elementProps.helperText = data.helperText
        elementProps.id = data.id
        elementProps.min = data.min
        elementProps.max = data.max
        break

      case 'checkBox':
        ElementComponent = CheckBox
        elementProps.id = data.id
        elementProps.text = data.placeholder
        elementProps.required = data.required
        break

      case 'passwordWithRetype':
        const { settings: { technical: { passwordPatterns } = {} } = {} } = PageData.appFrame || {}

        ElementComponent = PasswordWithRetype
        elementProps.data = {
          ...data,
          passwordStrength: {
            text: IntlUtil.label(PortalLabels.PASSWORD_STRENGTH_TEXT),
            patterns: passwordPatterns,
            description: [
              {
                color: 'red',
                caption: IntlUtil.label(PortalLabels.PASSWORD_STRENGTH_BAD),
              },
              {
                color: 'red',
                caption: IntlUtil.label(PortalLabels.PASSWORD_STRENGTH_BAD),
              },
              {
                color: 'orange',
                caption: IntlUtil.label(PortalLabels.PASSWORD_STRENGTH_WEAK),
              },
              {
                color: 'yellow',
                caption: IntlUtil.label(PortalLabels.PASSWORD_STRENGTH_OKAY),
              },
              {
                color: 'green',
                caption: IntlUtil.label(PortalLabels.PASSWORD_STRENGTH_GOOD),
              },
            ],
          },
        }
        break

      case 'rating':
        ElementComponent = RatingField
        elementProps.data = data
        break

      default: {
        ElementComponent = TextInputField
        elementProps.data = data
      }
    }

    return <ElementComponent {...otherProps} {...elementProps} />
  }

  _handleFieldFocus = () => {
    this.setState({ isInvalid: false, externalError: undefined })

    const { onFieldFocus } = this.props
    onFieldFocus && onFieldFocus()
  }

  checkValidity = () => {
    if (!this._ref) return true

    this._ref.blur && this._ref.blur()

    if (!this._ref.checkValidity) {
      return true
    }

    const isInvalid = !this._ref.checkValidity()

    this.setState({ isInvalid })
    return !isInvalid
  }

  getValue = () => (this._ref && this._ref.getValue ? this._ref.getValue() : undefined)
}

const styles = ({ spacing: { unit } }) => ({
  element: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: unit,
    },
  },
  textLine: {
    marginBottom: unit * 2,
  },
  smallButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

export default withStyles(styles)(FormElement)
