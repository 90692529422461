import get from 'lodash/get'
import { predefinedSchemas } from './PresetComponentsList'

export function getJsonPath(parentPath = '', fieldIndex = 0, fieldName = '') {
  const index = fieldIndex !== -1 && `[${fieldIndex}]`
  return [parentPath, index, fieldName].filter(Boolean).join('.')
}

// TODO: move this to useJsonData
export function getJsonValue(jsonPath = '', jsonData = []) {
  return get(jsonData, jsonPath)
}

export function getListSchema(components = []) {
  if (!components[0]) return
  const schema = { component: { type: 'preset', name: components[0] } }
  for (const key of components) {
    const fields = predefinedSchemas[key]
    Object.assign(schema, fields)
  }
  return schema
}

export function getDefaultJsonValue(schema) {
  const value = {}
  for (const [key, val] of Object.entries(schema)) {
    switch (val?.type) {
      case 'string':
      case 'enum':
        value[key] = ''
        break
      case 'number':
        value[key] = 0
        break
      case 'boolean':
        value[key] = false
        break
      case 'array':
        value[key] = [getDefaultJsonValue(val.schema || {})]
        break
      case 'list':
        const listSchema = getListSchema(val.components)
        value[key] = getDefaultJsonValue(listSchema) || {}
        break
      case 'preset':
        value[key] = val.name
        break
      case 'richtext':
        value[key] = {
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              attrs: {
                textAlign: 'left',
              },
            },
          ],
        }
        break
      default:
        // In case the type is unknown no value will be defined
        break
    }
  }
  return value
}
