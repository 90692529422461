import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect } from 'react'

const styles = () => ({
  atlasDashboardTitle: {},
  atlasDashboardDescription: {},
  atlasDashboardContainer: {
    width: '100%',
  },
  atlasDashboardWrapper: {
    height: '100%',
    boxSizing: 'border-box',
  },
  atlasDashboardTitleContainer: {
    padding: '2.4rem 1.6rem 1.6rem 1.6rem',
  },
  atlasDashboardIcon: {
    width: '100%',
    maxHeight: '300px',
    aspectRatio: '3 / 2',
    objectFit: 'cover',
  },
  atlasDashboardCopy: { paddingTop: '1rem' },
})

function AtlasOverviewPage(props) {
  const { allDashboards } = props.data
  const { classes } = props

  useEffect(() => {
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    const resetFilters = params.get('resetFilters')
    if (resetFilters) {
      // Only clear out dashboard filters
      for (const [key] of Object.entries(window.localStorage)) {
        if (key.startsWith('dashboard-filters:')) window.localStorage.clear(key)
      }
    }
  }, [])

  return (
    <Grid container columns={12} justify="center" spacing={24}>
      {allDashboards
        ?.sort((a, b) => a.order - b.order)
        ?.map(({ dashboardId, name, description, dashboardMedia }) => {
          return (
            <Grid
              className={classes.atlasDashboardContainer}
              item
              component={'a'}
              href={`/atlas-dashboards/${dashboardId}`}
              lg={4}
              sm={6}
              md={4}
            >
              <Paper className={classes.atlasDashboardWrapper}>
                <img
                  className={classes.atlasDashboardIcon}
                  src={dashboardMedia?.path || '/static/images/default-dashboard.png'}
                  alt={name}
                />
                <div className={classes.atlasDashboardTitleContainer}>
                  <Typography variant="h4">{name}</Typography>

                  <Typography variant="body1" className={classes.atlasDashboardCopy}>
                    {description}
                  </Typography>
                </div>
              </Paper>
            </Grid>
          )
        })}
    </Grid>
  )
}

export default withStyles(styles)(AtlasOverviewPage)
