import { Button, Typography } from '@material-ui/core'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import React, { useState } from 'react'

import sizeImagePath from '../../../../shared/utils/sizeImagePath'
import PresetMediaSelection from './PresetMediaSelection'
import { useJsonData } from './StructuredJson'
import { getJsonValue } from './jsonFieldHelpers'

export default function PresetMediaPreview(props) {
  const { jsonPath, label, mediaProps = {}, api, onUpdate } = props
  const { getJsonData } = useJsonData()
  const jsonData = getJsonData()

  const pathSegments = jsonPath.split('.')
  const lastIndex = pathSegments.length - 1
  const buildJsonPathToProp = (propName) =>
    pathSegments
      .map((segment, index) => {
        return index === lastIndex ? propName : segment
      })
      .join('.')
  const mediaPathJsonPath = buildJsonPathToProp(mediaProps.path || 'path')
  const mediaTypeJsonPath = buildJsonPathToProp(mediaProps.type || 'type')
  const mediaPath = getJsonValue(mediaPathJsonPath, jsonData)
  const mediaType = getJsonValue(mediaTypeJsonPath, jsonData)
  const isImage = /image/.test(mediaType)

  const [openPopup, setOpenPopup] = useState(false)

  const handleClose = () => {
    setOpenPopup(false)
  }

  const handleUpdate = ({ path, type }) => {
    const currentJsonData = getJsonData()
    const newData = cloneDeep(currentJsonData)
    set(newData, mediaPathJsonPath, path)
    set(newData, mediaTypeJsonPath, type)
    if (JSON.stringify(currentJsonData) !== JSON.stringify(newData)) {
      onUpdate && onUpdate(newData)
    }
    setOpenPopup(false)
  }

  return (
    <div
      style={{
        float: 'left',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {label && <Typography style={{ fontSize: 11, marginBottom: 2 }}>{label}</Typography>}
      <a
        href={mediaPath ? mediaPath : null}
        target="_blank"
        rel="noreferrer"
        style={{
          display: 'block',
          width: 80,
          height: 60,
          marginBottom: 6,
          borderRadius: 4,
          ...(mediaPath && {
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: '#ccc',
            backgroundImage: isImage
              ? `url(${sizeImagePath(mediaPath, 'xxs')})`
              : 'url(/static/images/video_black.png)',
          }),
          ...(!mediaPath && { backgroundColor: '#ddd' }),
        }}
      >
        <span className="sr-only">{mediaPath}</span>
        {!mediaPath && (
          <span
            style={{
              display: 'block',
              width: 80,
              height: 96,
              borderLeft: '1px solid #ccc',
              transformOrigin: '0px 0px',
              transform: 'rotate(-54deg)',
              pointerEvents: 'none',
              position: 'relative',
              top: 2,
              left: 1,
            }}
          ></span>
        )}
      </a>
      <Button variant="outlined" size="small" onClick={() => setOpenPopup(true)}>
        {mediaPath ? 'change' : 'select'}
      </Button>
      <PresetMediaSelection api={api} open={openPopup} onClose={handleClose} onUpdate={handleUpdate} />
    </div>
  )
}
