import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import InputLabel from '@material-ui/core/InputLabel'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { withApi } from '../../../../shared/api/ApiContext'
import Flow from './Flow'

const styles = () => ({
  root: {
    position: 'relative',
  },
  flows: {
    marginTop: 32,
  },
  addFlowButton: {
    width: '100%',
  },
  switcher: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 4,
    overflow: 'hidden',
    border: '1px solid #EFEFEF',
  },
  switcherButton: {
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FAFAFA',
  },
})

class ActivationAppFlowConfig extends React.Component {
  async componentDidMount() {}

  generateNewFlow() {
    return {
      id: 'new-flow',
      key: Math.random().toString(16).slice(2),
      enabled: false,
      sourceType: '',
      labels: '{}',
      custom: '{}',
      config: {
        showQrCode: {
          enabled: false,
          method: 'signup-form',
          // Possible values:
          // signup-form
          // ploom-profile
        },
        scanCustomer: {
          enabled: false,
          method: 'confirm-via-scan',
          // Possible values:
          // check-existing
          // confirm-via-scan
          // confirm-via-mail
        },
        scanDevice: {
          enabled: false,
          method: 'attach',
          // Possible values:
          // attach
          // anonymous
        },
        attachData: {
          enabled: false,
          method: 'scan-qr-code',
          // Possible values:
          // scan-qr-code
        },
      },
    }
  }

  getSanitizedValue = () => {
    let {
      value = [],
      settings: { minItems = 0 },
    } = this.props

    if (value.length < minItems)
      value = [...value, ...new Array(minItems - value.length).fill(this.generateNewFlow())]

    return value
  }

  handleAddFlow = () => {
    const value = [...this.getSanitizedValue(), this.generateNewFlow()]

    this.props.onChange({
      target: {
        value,
        name: this.props.name,
      },
    })
  }

  handleRemoveFlow(id) {
    const value = [...this.getSanitizedValue()]

    const index = value.map((v) => v.id).indexOf(id)
    if (index !== -1) value.splice(index, 1)

    this.props.onChange({
      target: {
        value,
        name: this.props.name,
      },
    })
  }

  handleChangeFlow(index, newFlowData) {
    const value = [...this.getSanitizedValue()]
    value[index] = newFlowData

    this.props.onChange({
      target: {
        value,
        name: this.props.name,
      },
    })
  }

  render() {
    let {
      // selectedLanguage,
      // value,
      classes,
      className,
      label,
    } = this.props

    const value = this.getSanitizedValue()

    return (
      <div className={classNames(classes.root, className)}>
        <InputLabel style={{ position: 'inherit' }} shrink>
          {label}
        </InputLabel>

        <div className={classes.flows}>
          <div className={classes.switcher}>
            <div className={classes.switcherButton}>
              <Icon fontSize="small">list</Icon>
            </div>
            <div className={classes.switcherButton}>
              <Icon fontSize="small">code</Icon>
            </div>
          </div>
          {value.map((v, i) => {
            return (
              <Flow
                key={v.key}
                initialData={v}
                onRemove={(id) => this.handleRemoveFlow(id)}
                onChange={(newFlowData) => this.handleChangeFlow(i, newFlowData)}
              />
            )
          })}
        </div>

        <Button
          className={classes.addFlowButton}
          size="medium"
          variant="outlined"
          onClick={this.handleAddFlow}
        >
          <Icon className={classes.actionIcon}>add</Icon>Add new flow
        </Button>

        {/* <pre>{JSON.stringify(value, null, 2)}</pre> */}
      </div>
    )
  }
}

export default withApi(withStyles(styles)(ActivationAppFlowConfig))
