import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { Component } from 'react'

import PaperBox from '../../../shared/components/atoms/PaperBox'
import FormElementsGroup from '../../../shared/components/organisms/FormElementsGroup'
import SystemPageTemplate from '../../../shared/components/templates/SystemPageTemplate'
import { breakLine } from '../../../shared/utils'

class AdminLoginSetPasswordPage extends Component {
  render() {
    const { classes, title, text, elements, buttonLabel } = this.props

    return (
      <SystemPageTemplate title={title} className={classes.container}>
        {elements && (
          <PaperBox className={classes.box}>
            <Typography className={classes.textField}>{breakLine(text)}</Typography>
            <form noValidate className={classes.fullWidth}>
              <FormElementsGroup
                elements={elements}
                innerRef={(ref) => {
                  this._fieldsRef = ref
                }}
              />
            </form>
            <div align="center">
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={this._handleButtonClick}
              >
                {buttonLabel}
              </Button>
            </div>
          </PaperBox>
        )}
      </SystemPageTemplate>
    )
  }

  _handleButtonClick = async () => {
    if (!this._fieldsRef || !this._fieldsRef.checkValidity()) {
      return
    }

    const { api } = this.props
    api.setPassword({ form: this._fieldsRef.getValues() })
  }
}

const styles = ({ spacing: { unit } }) => ({
  fullWidth: {
    width: '100%',
  },
  button: {
    marginTop: unit,
    marginBottom: unit * 2,
  },
  textField: {
    marginBottom: unit,
  },
  box: {
    paddingBottom: unit * 2,
  },
  container: {
    height: '100%',
  },
})

export default withStyles(styles)(AdminLoginSetPasswordPage)
