import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid/Grid'
import Card from '@material-ui/core/Card/Card'
import CardMedia from '@material-ui/core/CardMedia/CardMedia'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Icon from '@material-ui/core/Icon/Icon'
import { styles } from './Styles'

class MediaItem extends React.Component {
  state = { isHover: false }

  // helps react window to perform better, the file._id is reliable to validate the rerender
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.file._id !== this.props.file._id || nextState !== this.state) {
      return true
    }
    return false
  }

  render() {
    const {
      classes,
      position,
      k,
      file,
      deleteFile,
      viewFullImage,
      useThisMedia,
      isList,
      contentType,
    } = this.props
    const { isHover } = this.state

    return (
      <Grid item key={k} style={{ ...position.style, padding: 12 }}>
        <Card
          className={classNames(classes.cardList, classes.card)}
          onMouseEnter={() => this.setState({ isHover: true })}
          onMouseLeave={() => this.setState({ isHover: false })}
        >
          <CardMedia
            image={contentType(file)}
            className={!/image/.test(file.type) ? classes.media : classNames(classes.media, classes.preview)}
            title={file.name}
          />
          {isHover && (
            <div className={classes.overlay}>
              <div className={classes.actionsContent}>
                {deleteFile && (
                  <IconButton
                    className={classes.actionsIcons}
                    onClick={deleteFile.bind(this, file, k)}
                    aria-label="Delete image"
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                )}
                {viewFullImage && (
                  <IconButton
                    className={classes.actionsIcons}
                    aria-label="View the full image"
                    onClick={viewFullImage.bind(this, file)}
                  >
                    <Icon>search</Icon>
                  </IconButton>
                )}
                {!isList && (
                  <IconButton
                    className={classes.actionsIcons}
                    onClick={useThisMedia.bind(this, file, k)}
                    aria-label="Use it"
                  >
                    <Icon>check</Icon>
                  </IconButton>
                )}
              </div>
            </div>
          )}
        </Card>
      </Grid>
    )
  }
}

export default withStyles(styles)(MediaItem)
