import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core'
import React from 'react'

export const MultipleSelectField = ({ label, data, name, id, onChange }) => {
  const [excludedPromotions, setExcludedPromotions] = React.useState([])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    // Ensure that the value is always an array
    const newSelection = Array.isArray(value) ? value : [value]

    setExcludedPromotions(newSelection)
    onChange && onChange(id, newSelection)
  }

  return (
    <div>
      <FormControl fullWidth={true}>
        <InputLabel id="multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="multiple-chip-label"
          id="multiple-chip"
          sx={{ px: 4 }}
          multiple
          value={excludedPromotions}
          onChange={handleChange}
          input={<OutlinedInput name={name} id={id} label="Chip" style={{ width: 600 }} />}
          renderValue={(selected) => (
            <div sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected?.map(({ value, label }) => (
                <Chip key={value} label={label} />
              ))}
            </div>
          )}
        >
          {data?.map((item) => (
            <MenuItem key={item.value} value={item}>
              <Checkbox checked={excludedPromotions.some((excluded) => excluded.value === item.value)} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
